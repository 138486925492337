<template>
  <p>Calendar {{ data }}</p>
  <p>coucou</p>
</template>

<script setup>
import axios from 'axios';
import { useQuery } from '@tanstack/vue-query';

const fetchEmployees = async () => {
  const response = await axios.get('/api/employees', {
    params: {
      isActive: true,
      'order[user.createdAt]': 'desc',
    },
  });
  return response.data;
};

// const { data, error, isLoading } = useQuery(['employees'], fetchEmployees);
const { isPending, isFetching, isError, data, error } = useQuery({
  queryKey: ['employees'],
  queryFn: fetchEmployees,
})

console.log('coucou:', data);
</script>